import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import {LogtoProvider, LogtoConfig, UserScope} from '@logto/react'
import {BrowserRouter} from "react-router-dom"

const logtoConfig: LogtoConfig = {
    endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
    appId: import.meta.env.VITE_LOGTO_APP_ID,
    scopes: [
        "read:chat", "write:chat",
        "read:files", "write:files",
        "read:assistants", "write:assistants",
        "read:integrations", "write:integrations",
        "read:conversations",
        "read:dashboard",
        UserScope.Organizations,
        UserScope.Email
    ],
    resources: [
        import.meta.env.VITE_LOGTO_API_ID
    ]
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <LogtoProvider config={logtoConfig}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </LogtoProvider>
    </React.StrictMode>,
)
