//
//
//  Sign In Callback Page
//
//

import {useHandleSignInCallback} from "@logto/react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


function SignInCallback() {
    const navigate = useNavigate()

    const { isLoading } = useHandleSignInCallback(() => {
        navigate("/")
    })

    useEffect(() => {
        if (!isLoading) {
            navigate("/")
        }
    }, [isLoading, navigate])

    // When it's working in progress
    if (isLoading) {
        return <div>Redirecting...</div>
    }
}

export default SignInCallback
